<template>

<div>

	<com-title text="Checkout" />

	<com-item route="Convention.Checkout.Overview" text="Overview" />
	<com-item route="Convention.Checkout.Loans" text="Loans" :count="$convention.count.checkout.active" />
	<com-item route="Convention.Checkout.Wins" text="Play to Win" />
	<com-item route="Convention.Checkout.Reports" text="Reports" />
	<com-item route="Convention.Checkout.Settings" text="Settings" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../..//panel/common/Title'),
		'com-item': () => import('./../..//panel/common/Item')
	}

}

</script>

<style scoped>

</style>
